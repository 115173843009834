import { defineStore } from 'pinia'
import type { IBreadcrumb } from '@/utils/types/IBreadcrumb'

export const useGlobalStore = defineStore(
  'globalStore',
  () => {
    // 全站網站名稱
    const WEB_TITLE = ref('恆隆行')

    const isDark = ref(false)

    const isWhite1 = ref(false)

    const isWhite = ref(false)

    const isHeaderTransparent = ref(false)

    const isNotFixed = ref(false)

    // 手機選單是否開啟
    const isMobileMenuOpen = ref(false)

    const breadcrumbs = ref<IBreadcrumb[]>([])

    const setBreadcrumbs = (data: IBreadcrumb[]) => {
      breadcrumbs.value = data
    }

    const isScrollMenuClose = ref(true) // 手機版捲動頁尾選單是否隱藏

    // 是否顯示 layout
    const showLayout = ref(false)
    const setShowLayout = (data: boolean) => {
      showLayout.value = data
    }
    // 是否顯示全站dialog
    interface IDialogData {
      banner?: number
      eventType?: string
      needLogin?: boolean
      visible: boolean
      title?: string
      message?: string
      html?: string
      confirmButtonText?: string
      showCancelButton?: boolean
      type?: string
    }
    const dialogData = ref({
      eventType: 'tips',
      needLogin: false,
      visible: false,
      title: '提示',
      message: '',
      html: '',
      confirmButtonText: '確認',
      showCancelButton: false,
      banner: 1,
      type: 'success'
    })
    const setDialogVisible = (data: IDialogData) => {
      dialogData.value.visible = data.visible
      dialogData.value.eventType = data.eventType || 'tips'
      dialogData.value.title = data.title || '提示'
      dialogData.value.message = data.message || ''
      dialogData.value.html = data.html || ''
      dialogData.value.confirmButtonText = data.confirmButtonText || '確認'
      dialogData.value.showCancelButton = data.showCancelButton || false
      dialogData.value.type = data.type || 'success'
      dialogData.value.needLogin = data.needLogin === true
      dialogData.value.banner = data.banner ?? 1
    }

    const seoDescriptions = ref({
      home: '60多年來，恆隆行不僅精選代理各國好物 ，更是連結與每一位顧客日常的生活風格品牌，致力於幫助每一個熱愛生活的人找生活，豐富生活的無限想像。',
      career: '恆隆行專注於人才培育及專業能力的養成，讓每一個人依照個人特質與專長，在管理或專業領域中獲取良好的發展。',
      news: '想了解恆隆行的最新動態？恆隆行最新活動公告都在這裡！點擊了解更多，最新資訊不錯過。',
      services: '恆隆行提供會員完整的售後服務，包含產品維修、保固註冊、到府服務、選品服務、深層清潔等，滿足會員的種種需求，歡迎申請。',
      faq: '恆隆行產品的常見問題都可以在這邊找到相關文章內容，歡迎來查詢，解決你的問題。',
      contact: '恆隆行非常重視您的意見，若您有任何疑問，可先參考網頁上「常見問題」的解答，或者直接在此處留下問題，亦可於上班時間聯絡客服人員，我們將竭誠為您服務。',
      select: '藉由恆隆行代理的眾多商品，邀請您與我們交換日常中的生活經驗，了解您的品味與生活樣貌，為您量身打造最適合您的生活提案 。',
      warranty: '恆隆行提供代理產品的保固註冊服務，如 dyson、honeywell、sodastream、braun 等品牌，以保障您購買的產品享受原廠認證售後服務，歡迎線上申請',
      repair: '恆隆行提供代理產品的產品維修服務，如 dyson、honeywell、sodastream、braun 等品牌，以保障您購買的產品享受原廠認證售後服務，歡迎線上申請',
      fastRepair: '恆隆行提供會員速修服務，即可將已完成保固註冊之指定商品送修，在官網完成線上預約並經客服聯繫通知確認後，於指定預約時段，攜產品至現場進行維修，最快40分鐘內即可完成維修。',
      inhome: '恆隆行產品專家將在您預約的時間免費到府上，依照您購買的機型與使用情境，與您解說商品組裝、使用與保養清潔，豐富您的使用歷程，為您生活帶來的更大的便利。',
      cleaning: '恆隆行針對 Dyson 吸塵器指定手持系列提供深層清潔服務，去除內部氣旋筒、集塵筒、配件長期累積的粉塵和污垢，再搭配瑞士品牌 LAURASTAR IZZI PLUS 進行消毒，以及風乾後為機身打蠟。',
      events: '恆隆行舉辦多樣活動，只要成為恆隆行會員即可報名參加，歡迎線上報名！',
      member:
        "恆隆行協助每個熱愛生活的人，尋找生活中的亮點，體驗挑戰每一個精彩的時刻。 完成恆隆行會員註冊並通過身份驗證，即可加入「生活有亮點計劃」享受會員專屬服務。 Let's be Sparkling，閃亮自己豐盛生活！",
      membership:
        "恆隆行協助每個熱愛生活的人，尋找生活中的亮點，體驗挑戰每一個精彩的時刻。 完成恆隆行會員註冊並通過身份驗證，即可加入「生活有亮點計劃」享受會員專屬服務。 Let's be Sparkling，閃亮自己豐盛生活！",
      privacy: '恆隆行對每一位會員的隱私權採取高度尊重且保護的態度，所以將明確地揭露隱私權保護政策，讓您了解恆隆行如何取得、使用並進一步保護您的個人資訊。',
      terms: '提供會員便利的交易。為了保障您的權益，請務必詳讀本條款，在您註冊成為本網站會員或開始使用本服務時，即視為您已經閱讀、瞭解並同意接受本條款。',
      blog: '歡迎線上探索恆隆行品牌部落格的專題內容！',
      subBlog: '歡迎線上探索:name的專題文章內容！',
      locaion: '立即查看恆隆行:name專櫃的地點、營業時間及聯絡資訊。',
      hence: 'hence, 雜誌是恆隆行的全新自媒體，「因此（hence），我們不止於現狀」，就像在逗號之後的留白，讓恆隆行與每一個你續寫著各種想像，陪伴每位讀者跳脫日常的慣性，翻開生活的下一頁。',
      henceCategory: '歡迎線上探索 hence, 雜誌的:name內容！',
      recipe: '想學會如何做菜，就來恆食堂找食譜！各式料理的烹飪時間、步驟、食材讓你一次掌握，立即閱讀',
      recipeDetail: '想學會如何做出:name，就來恆食堂找食譜！:name烹飪時間、步驟、食材讓你一次掌握，立即閱讀',
      magazine: '歡迎探索 hence, 雜誌的歷期雜誌列表，與你一起看見另一種過生活的方式。',
      topic: '歡迎探索 hence, 雜誌的專題報導列表，與你一起看見另一種過生活的方式。',
      stroy: '60多年來，恆隆行不僅精選代理各國好物 ，更是連結與每一位顧客日常的生活風格品牌，致力於幫助每一個熱愛生活的人找生活，豐富生活的無限想像。',
      search: '你正在找:keyword的相關文章嗎？hengstyle恆隆行目前共有:total篇文章與:keyword有關，歡迎閱讀！',
      author: '歡迎探索 hence, 雜誌的:name文章列表，與你一起看見另一種過生活的方式。',
      zonezone: '新世代的概念店「zonezone」讀取音近似中文「種種」、「林林總總」的寓意，結合飲食、文化、體驗及服務，打造多元的種種生活嚮往，為你探索無限可能的新生活提案',
      sustainability: '恆隆行相信生活有各種面貌，一種生活有多種詮釋、更有百種選擇，我們從健康純淨、友善地球、創新獨特、社會共創四種不同的面向來演繹可能的生活選擇，讓生活大於想像。',
      sitemap: null
    })

    return {
      WEB_TITLE,
      isDark,
      isWhite,
      isWhite1,
      isNotFixed,
      isHeaderTransparent,
      isMobileMenuOpen,
      breadcrumbs,
      setBreadcrumbs,
      isScrollMenuClose,
      showLayout,
      setShowLayout,
      dialogData,
      setDialogVisible,
      seoDescriptions
    }
  },
  {
    persist: {
      storage: process.client ? localStorage : null,
      pick: ['isDark', 'isWhite', 'isWhite1', 'isNotFixed', 'isHeaderTransparent', 'isMobileMenuOpen', 'isScrollMenuClose', 'showLayout']
    }
  }
)
