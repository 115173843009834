import { type IReCaptchaComposition } from 'vue-recaptcha-v3'
import { useDateFormat, useWindowSize } from '@vueuse/core'

const { width } = useWindowSize()

export function objectToSearch(params: Record<string, any>) {
  let searchParams = ''
  if (Object.keys(params).length > 0) {
    for (const key in params) {
      if (!isEmpty(params[key])) {
        searchParams += `${key}=${params[key]}&`
      }
    }
    searchParams = searchParams.slice(0, searchParams.length - 1)
  }
  return searchParams
}

// empty/ null/ undefined
export function isEmpty(value: any) {
  if (value === null || typeof value === 'undefined') {
    return true
  }

  if (typeof value === 'string' && value.trim().length === 0) {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true
  }

  return false
}

export function extractVideoID(url: string) {
  const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/
  const match = url.match(regex)
  return match ? match[1] : null
}

export async function useReCaptchaV3(useReCaptcha: IReCaptchaComposition, action: string) {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha
  await recaptchaLoaded()
  const token = await executeRecaptcha(action)

  return token
}

export function formatNumber(number: number) {
  return new Intl.NumberFormat().format(number)
}

export function monthsDiff(date1: string, date2: string) {
  let startDate = new Date(date1)
  let endDate = new Date(date2)

  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12
  months -= startDate.getMonth()
  months += endDate.getMonth()

  return months <= 0 ? 0 : months
}

export function dateFormat(date: any, format: string = 'YYYY-MM-DD', locales: string = 'zh-TW'): string {
  const v = useDateFormat(date, format, { locales })
  return v.value
}

export const objectToBase64 = (data: any) => {
  const jsonStr = JSON.stringify(data)
  const base64Str = btoa(jsonStr)

  return base64Str
}

export const base64ToObject = (base64Str: string) => {
  try {
    const decode = decodeURIComponent(base64Str)
    const jsonStr = atob(decode)
    return JSON.parse(jsonStr)
  } catch (error) {
    console.error('解析 JSON 參數:', base64Str)
    console.error('解析 JSON 錯誤:', error)
  }

  return null
}

// 過濾Object
export const filterObject = (obj: any, fn: any) => {
  return Object.keys(obj).reduce((filteredObj: any, key) => {
    if (obj[key] !== fn) {
      filteredObj[key] = obj[key]
    }
    return filteredObj
  }, {})
}

// 判斷是否為空值
export const isNullOrEmptyOrUndefined = (str: string) => {
  return str === null || str === undefined || str === ''
}

// 轉換為form data
export const objectToFormData = (obj: any, form = new FormData(), namespace = '') => {
  Object.entries(obj).forEach(([key, value]) => {
    const formKey = namespace ? `${namespace}[${key}]` : key

    if (value instanceof Date) {
      form.append(formKey, value.toISOString())
    } else if (value instanceof File) {
      form.append(formKey, value, value.name)
    } else if (value instanceof Blob) {
      form.append(formKey, value)
    } else if (typeof value === 'object' && value !== null) {
      objectToFormData(value, form, formKey)
    } else {
      form.append(formKey, value)
    }
  })

  return form
}

// 判斷是否為手機
export const isMobile = () => {
  if (process.client) {
    const userAgent = navigator.userAgent.toLowerCase()

    return /iphone|ipad|ipod|android/i.test(userAgent)
  }

  return false
}

// 發票qr code解析
export const parseInvoiceQRCode = (qrCode: string) => {
  const invoiceData = {
    invoiceNumber: '',
    invoiceDate: '',
    randomNumber: '',
    salesAmount: 0,
    totalAmount: 0,
    buyerId: '',
    sellerId: ''
  }

  if (qrCode && qrCode.length >= 55) {
    // 發票字軌
    invoiceData.invoiceNumber = qrCode.substring(0, 10)
    // 發票開立日期
    const rocDate = qrCode.substring(10, 17)
    const year = parseInt(rocDate.substring(0, 3), 10) + 1911 // 民國年轉西元年
    const month = rocDate.substring(3, 5)
    const day = rocDate.substring(5, 7)
    invoiceData.invoiceDate = `${year}-${month}-${day}`
    // 隨機碼
    invoiceData.randomNumber = qrCode.substring(17, 21)
    // 銷售額 (需從十六進位轉為十進位)
    invoiceData.salesAmount = parseInt(qrCode.substring(21, 29), 16)
    // 總計額 (需從十六進位轉為十進位)
    invoiceData.totalAmount = parseInt(qrCode.substring(29, 37), 16)
    // 買方統一編號
    invoiceData.buyerId = qrCode.substring(37, 45)
    // 賣方統一編號
    invoiceData.sellerId = qrCode.substring(45, 53)
  }

  return invoiceData
}

// 判斷是否為email
export const isEmail = (str: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  return regex.test(str)
}

export const repairTypeDisplay = (type: string) => {
  let display = '維修'
  if (type === 'deep') {
    display = '深層清潔'
  }
  if (type === 'fast') {
    display = '速修'
  }
  return display
}

// google calendar
export const createGoogleCalendarEvent = (title: string, content: string, eventDateTime?: string, location?: string) => {
  if (process.client && eventDateTime) {
    const formattedTitle = encodeURIComponent(title)
    const formattedContent = encodeURIComponent(content)
    const formattedLocation = encodeURIComponent(location ?? '')
    const bookingStartDate = dateFormat(eventDateTime, 'YYYYMMDDTHHmmss')
    const newDate = new Date(eventDateTime)
    newDate.setHours(newDate.getHours() + 1)
    const bookingEndDate = dateFormat(newDate, 'YYYYMMDDTHHmmss')

    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${formattedTitle}&details=${formattedContent}&dates=${bookingStartDate}/${bookingEndDate}&location=${formattedLocation}`
    window.open(googleCalendarUrl, '_blank')
  }
}

// 彈跳視窗尺寸
export const dialogResize = computed(() => {
  if (width.value <= 768) {
    return '85%'
  } else if (width.value <= 1024) {
    return '50%'
  } else {
    return '30%'
  }
})

export const convertNewlinesToBreaks = (str: string) => {
  return str.replace(/\n/g, '<br>')
}

// 西元年轉民國年
export function toTWDateObject(dateString: string) {
  const date = new Date(dateString)

  // 計算民國年份
  const minguoYear = date.getFullYear() - 1911
  // 獲取月份、日期、時、分、秒
  const month = date.getMonth() + 1 // 月份從0開始，所以加1
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  // 返回一個包含民國年、月、日、時、分、秒的物件
  return {
    year: minguoYear,
    month: month,
    day: day,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

// 當天日期前才能選擇
export function disabledDateBeforeToday(current: any) {
  return current && current > new Date()
}

function normalizeDate(dateString: any) {
  const date = new Date(dateString)
  if (date.toString() === 'Invalid Date') {
    return 'NaN'
  }
  return useDateFormat(date, 'YYYY-MM-DD').value
}

export function isValidDate(dateString: any) {
  // dateString 是否為Date
  
  //判斷是否為YYYY-MM-DD 分別是否為數字
  if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) {
    return false
  }
  // 嘗試將輸入的字符串格式化為 'YYYY-MM-DD'
  const formatted = useDateFormat(dateString, 'YYYY-MM-DD').value

  // 如果格式化後的日期與輸入的日期相同，則認為日期有效
  // 注意：我們需要標準化輸入的日期字符串，以處理像 '2024-1-1' 這樣的情況
  const normalizedInput = normalizeDate(dateString)
  return formatted === normalizedInput
}
