import Http from '@/utils/http'
import type { ISSOLoginData, IUpdateProfileForm, IUserOrderFilter, IFilter, IUserRepairsFilter } from '../types'

export class UserApi {
  // sso會員登入
  public postLogin(params: ISSOLoginData) {
    return Http.post('/auth/login', params)
  }

  // 取得會員訂單列表
  public getOrders(params: IUserOrderFilter = {}) {
    const _queryParams = {
      sorting: 'id',
      ...params
    }
    const queryParams = new URLSearchParams()
    Object.keys(_queryParams).forEach(key => {
      Array.isArray(_queryParams[key]) ? _queryParams[key].forEach(v => queryParams.append(`${key}[]`, v)) : queryParams.append(key, _queryParams[key])
    })
    return Http.get(`/user/orders?${queryParams.toString()}`)
  }

  // 取得會員訂單詳細資料
  public getOrder(no: string) {
    return Http.get(`/user/orders/${no}`)
  }

  // 取得會員保固列表
  public getWarranties(type: string = 'general', params: IFilter = {}) {
    const queryParams = { page: 1, per_page: 999, ...params }
    return Http.get(`/user/warranties/${type}`, queryParams)
  }

  // 重新上傳保固證明
  public reUploadWarranty(id: string, params: FormData) {
    return Http.post(`/user/upload_warranty_medias/${id}`, params)
  }

  // 重新上傳贈品發票
  public reUploadGiftInovice(id: string, params: FormData) {
    return Http.post(`/user/upload_gift_medias/${id}`, params)
  }

  // 取得會員資料
  public getProfile() {
    return Http.get('/user/profile')
  }

  // 取得會員資料
  public updateProfile(params: IUpdateProfileForm) {
    return Http.post('/user/profile', params)
  }

  // 取消or加入收藏活動
  public toggleFavoriteEvent(id: string) {
    return Http.post(`/user/collect_events/${id}`)
  }

  // 取得收藏活動列表
  public getFavoriteEvent(params: IFilter = {}) {
    return Http.get('/user/favorite_events', params)
  }

  // 取得維修紀錄列表
  public getRepairs(params: IUserRepairsFilter = {}) {
    return Http.get('/user/repairs', params)
  }

  // 取得維修紀錄詳細資料
  public getRepair(id: string) {
    return Http.get(`/user/repairs/${id}`)
  }

  // 取消訂單(退票)
  public cancelOrder(id: string, params: { reason: Array<string> }) {
    return Http.post(`/user/cancel_order/${id}`, params)
  }
}

export default new UserApi()
