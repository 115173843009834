import { useUserStore, useGlobalStore } from '@/stores'
import Http from '@/utils/http'

export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('-----------SSO驗證START-----------')
  console.log('SSO: client', process.client)

  const tokenCookie = useCookie('t', { secure: true })
  const tokenCreatedAt = useCookie('tat', { secure: true })
  const globalStore = useGlobalStore()
  const userStore = useUserStore()
  const LIMITING_PATH = [
    '/events/*/apply',
    '/member/*',
    '/services/repair/form',
    '/services/inhome/form',
    '/services/fast-repair/form',
    '/services/cleaning/form',
    '/services/warranty/form',
    '/giftmain/*',
    '/giftchoose/*'
  ]
  const bannerID = {
    'services-repair-form': 3,
    'services-inhome-form': 6,
    'services-fast-repair-form': 4,
    'services-cleaning-form': 5,
    'services-warranty-form': 2,
    'gift-id-apply': 7,
    'events-eventId-apply': 8
  }
  console.log('SSO: tokenCookie', to.name)
  // 只在client端执行
  const path = to.path
  const outerid = to.query.Outerid as string
  const token = to.query.id_token as string
  // const invalidDate = to.query.invalidDate as string
  // const recode = to.query.recode as string

  // 限制路徑
  const isLimitedPath = LIMITING_PATH.some(limitedPath => {
    const regexPattern = limitedPath.replace(/\/\*/g, '(/.*)?')
    const regex = new RegExp(`^${regexPattern}$`)

    return regex.test(path)
  })

  const getBannerId = (name: string) => {
    return bannerID?.[name] || 1
  }

  if (process.server) {
    console.log('SSO: process.server')
    if (!userStore.profile?.id) {
      console.log('SSO: 判斷是sso參數是否回傳')

      // 登入且在將url參數清除
      if (outerid && token) {
        try {
          Http.setToken(null)
          const t = await userStore.fetchLogin({
            outerid,
            token
          })
          if (t) {
            tokenCookie.value = t
            tokenCreatedAt.value = new Date().getTime().toString()
            userStore.setToken(t)
            Http.setToken(t)
            await userStore.fetchUserProfile()
          }
        } catch (error) {
          tokenCookie.value = null
          console.warn('登入會失敗:', error)
        }

        let queryParams = to.query
        delete queryParams.Outerid
        delete queryParams.id_token
        delete queryParams.invalidDate
        delete queryParams.recode
        delete queryParams.state

        return navigateTo({ path: to.path, query: queryParams })
      }
    }
  }

  if (process.client && userStore.profile?.id) {
    // 延長token時間
    userStore
      .fetchLogin({
        outerid,
        token
      })
      .then(res => {
        if (res) {
          tokenCookie.value = res
          tokenCreatedAt.value = new Date().getTime().toString()
          userStore.setToken(res)
          Http.setToken(res)
        }
      })
      .catch(error => {
        userStore.logout()
        console.warn('延長token失敗:', error)
      })
  }

  if (!userStore.profile?.id) {
    // 未登入且在限制路徑
    if (isLimitedPath) {
      console.log('SSO: 限制路徑轉至sso登入頁面')
      tokenCookie.value = null
      const redirectUrl = import.meta.env.VITE_SSO_URL.replace('{:redirect_url}', import.meta.env.VITE_DOMAIN + encodeURIComponent(to.fullPath)).replace('{:banner_id}', getBannerId(to.name as string))

      return navigateTo(redirectUrl, { external: true })
    }
  }

  globalStore.setShowLayout(true)
  console.log('-----------SSO驗證END-----------')
})
